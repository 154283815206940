import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
            I’m an enthusiastic 
            <i>
                <b className="purple"> Computer Science & Engineering Undergraduate  </b>
              </i>
              with a strong passion for programming, especially in backend development. My focus is on building efficient, scalable, and reliable systems that drive innovation.
              <br />
              <br />
              My passion lies in crafting efficient and scalable backends that power applications. I’m driven by the challenge of building systems that are not only robust but also optimized for performance and seamless integration.
              <br />
              <br />
              Whenever possible, I also apply my passion for developing products on
              <i>
                <b className="purple">
                  {" "}
                  Modern Python Library and Frameworks.
                </b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar"style={{ paddingTop: "1px", paddingBottom: "10px" , zIndex: 5,}}>
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>YOU CAN FIND ME ON</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/leejawww"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/lijah-gongal-622b8a224/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/i_am_leejaw/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
            <p style={{ fontSize: "20px" }}>
              Feel free to <span className="purple">connect </span>with me
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;