import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hello Everyone, my name is <span className="purple">Lijah Babu Gongal. </span>
            <br/>
            I am from <span className="purple"> Bhaktapur, Nepal.</span> 🇳🇵
            <br />
            I am currently working as a backend developer. 👨🏻‍💻
            <br />
            I have completed <span className="purple">Bachelor of Technology in Computer Science & Engineering   </span> at VIT Vellore. 👨🏻‍🎓
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing football ⚽️
            </li>
            <li className="about-activity">
              <ImPointRight /> Painting & sketch 🎨
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling new places 🗺️
            </li>
            <li className="about-activity">
              <ImPointRight /> Enjoying cultural fests 🎊
            </li>
            <li className="about-activity">
              <ImPointRight /> Exploring different foods 🍜
            </li>
            <li className="about-activity">
              <ImPointRight /> Capturing beautiful pictures 📸
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;